export default class FeaturedProducts {
    constructor() {
        this.featuredProducts = null;
        this.options = {
            dots: true,
            arrows: true,
            autoplay: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
            ],
        };
    }

    init() {
        this.featuredProducts = $('.featured-products .products');
        this.create();
    }

    create() {
        if (this.featuredProducts.length < 1) {
            // console.warn('Invalid products slider selector');
            return;
        }
        if (this.isInitialized()) return;

        this.featuredProducts.slick(this.options);
        this.featuredProducts.parent().addClass('elementor-slick-slider');
    }

    isInitialized() {
        return this.featuredProducts.hasClass('slick-initialized');
    }
}
