import View from './components/view';

export default class ShiftNav {
    constructor({ menuWrapperSelector, minWidth = 0, maxWidth = 4000 }) {
        this.dataSourceSelector = menuWrapperSelector;
        this.minWidth = minWidth;
        this.maxWidth = maxWidth;

        this.root = null;
        this.dataSource = null;
        this.dataStructure = [];

        this.links = [];

        this.onReady = this.onReady.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    init() {
        $(this.onReady);
    }

    onReady() {
        this.dataSource = $(this.dataSourceSelector);
        this.root = $('<div class="shiftnav"></div>');
        this.dataSource.parent().prepend(this.root);

        this.dataStructure = this.getDataStructure();
        this.render(
            this.getRootLinks(),
            null,
            null,
            this.getMenuContentBefore(),
            this.getMenuContentAfter()
        );

        $(window).on('resize', this.onResize);

        $(document).on('click', '.shiftnav__toggle', this.onClick);
        $(document).on('click', '.shiftnav__item > .shiftnav__toggle', this.onClickMobile);
        $(document).on('click', '.shiftnav__back', this.onClick);
        $(document).on(
            'click',
            '.shiftnav__item a[href="#"]',
            this.onClickEmptyLink
        );

        this.toggle();
    }

    onResize() {
        this.toggle();
    }

    onClick(e) {
        const clickedEl = $(e.target);

        const menu = clickedEl.data('menu');
        const column = clickedEl.data('column');
        const block = clickedEl.data('block');

        this.render(
            this.getLinks(menu, column, block),
            this.getBackLink(menu, column, block),
            this.getRootLink(menu),
            this.getMenuContentBefore(menu),
            this.getMenuContentAfter(menu)
        );

        $('.mm_menus_ul').scrollTop(0);
    }

    onClickMobile(e) {
        const clickedEl = $(e.target);
        const children = $('.shiftnav__body')[0].children;
        let force_click = null;
        let mobile_force_open = null;
        const data_menu = clickedEl[0].getAttribute('data-menu');
        const data_name = clickedEl[0].getAttribute('data-name');
        const data_root = clickedEl[0].getAttribute('data-root');
        let back_btn = null;
        let counter = 0;
        for (var i = 0; i < children.length; i++) {
            mobile_force_open = children[i].querySelector('.mobile-force-open');
            if (mobile_force_open != null) {
                counter++;
                force_click = mobile_force_open;
            }
        }
        if(counter == 1) {
            force_click.click();
            back_btn =  $('.shiftnav__navlink--back > .shiftnav__toggle');
            back_btn[0].setAttribute('data-menu', data_menu);
            if(data_root == "true") back_btn[0].setAttribute('data-menu', null);
            $('.shiftnav__navlink--back > .shiftnav__toggle')[0].title = data_name;
            $('.shiftnav__navlink--back > .shiftnav__toggle')[0].innerHTML  = data_name;
        }
    }

    onClickEmptyLink(e) {
        e.preventDefault();
        const link = $(e.target);
        const parent = link.parent();
        const toggle = parent.find('.shiftnav__toggle');

        toggle.click();
    }

    render(
        links,
        backLink = null,
        showAllLink = null,
        beforeContent = null,
        afterContent = null
    ) {
        const view = View(
            links,
            backLink,
            showAllLink,
            beforeContent,
            afterContent
        );

        this.root.html(view);
    }

    toggle() {
        if (
            window.innerWidth >= this.minWidth &&
            window.innerWidth <= this.maxWidth
        ) {
            this.root.show();
            this.dataSource.hide();
        } else {
            this.root.hide();
            this.dataSource.show();
        }
    }

    getDataStructure() {
        const dataSource = this.dataSource.get(0);

        return this.getLinksFromNode(dataSource);
    }

    getLinksFromNode(node) {
        let elements = [];
        const rootMenus = $(node).find('[data-id-menu]');
        const blockMenus = $(node).find('[data-id-block]');

        for (const menu of rootMenus) {
            const link = $(menu).find('> a').get()[0];
            link.classList.add('level-0');
            elements.push({
                el: link,
                menu: $(menu).data('id-menu'),
                column: null,
                block: null,
                root: true,
                parent: $(menu).find('.mm_columns_ul > ul').length > 0,
            });
        }

        for (const block of blockMenus) {
            const blockLinks = $(block)
                .find('.mm_block_type_html .ets_mm_block_content ul > li > a')
                .get();
            const blockTitles = $(block)
                .find(
                    '.mm_block_type_html .ets_mm_block_content > a, .ets_mm_block_content > p > a'
                )
                .get();

            for (const link of blockTitles) {
                link.classList.add('level-1');
                elements.push({
                    el: link,
                    menu: $(block).data('id-parent-menu'),
                    column: $(block).data('id-parent-column'),
                    block: $(block).data('id-block'),
                    root: false,
                    parent: true,
                });
            }
            for (const link of blockLinks) {
                link.classList.add('level-2');
                elements.push({
                    el: link,
                    menu: $(block).data('id-parent-menu'),
                    column: $(block).data('id-parent-column'),
                    block: $(block).data('id-block'),
                    root: false,
                    parent: false,
                });
            }
        }

        return elements;
    }

    getMenuContentBefore(menu) {
        return $(
            '.mm_menus_li[data-id-menu=' + menu + '] .mm_menu_before'
        ).html();
    }

    getMenuContentAfter(menu) {
        return $(
            '.mm_menus_li[data-id-menu=' + menu + '] .mm_menu_after'
        ).html();
    }

    getRootLinks() {
        return this.dataStructure.filter((el) => el.root == true);
    }

    getLinks(menu, column, block) {
        if (menu && !column && !block) {
            return this.dataStructure.filter(
                (el) => el.menu == menu && el.parent == true && el.root == false
            );
        }

        if (menu && column && block) {
            return this.dataStructure.filter(
                (el) =>
                    el.menu == menu &&
                    el.column == column &&
                    el.block == block &&
                    el.parent == false &&
                    el.root == false
            );
        }

        return this.getRootLinks();
    }

    getBackLink(menu, column, block) {
        /* Root back link */
        if (menu && !column && !block) {
            const backLink = this.dataStructure.find(
                (el) =>
                    el.menu == menu && el.column == column && el.block == block
            );
            return {
                ...backLink,
                menu: null,
            };
        }

        /* Nested back link */
        if (menu && column && block) {
            const currentLink = this.dataStructure.find(
                (el) =>
                    el.menu == menu &&
                    el.column == column &&
                    el.block == block &&
                    el.parent == true
            );

            const backLink = this.dataStructure.find(
                (el) => el.menu == menu && el.parent == true
            );

            return {
                ...backLink,
                el: currentLink.el,
            };
        }

        return null;
    }

    getRootLink(menu) {
        return this.dataStructure.find(
            (el) => el.menu == menu && el.root == true
        );
    }
}
