export default function Link(item) {
    const elHasImage = !!item.el.querySelector('img');

    if (!elHasImage) {
        item.el.classList.add('no-image');
    }

    const el = item.el.outerHTML;

    let result = `<li class="shiftnav__item ${
        item.parent ? 'shiftnav__item--parent' : ''
    }">`;

    result += el;

    if (item.parent) {
        result += `<button 
            class="shiftnav__toggle" 
            data-menu="${item.menu}"
            data-column="${item.column}"
            data-block="${item.block}"
            data-root="${item.root}"
            data-name="${$(item.el).text().trim()}"
            ></button>`;
    }

    result += `</li>`;

    return result;
}
