export default class InputTooltip {
    init() {
        const self = this;
        $(function () {
            const elements = $('input[data-tooltip]');

            /**
             * Add tooltips to every input with data-tooltip attribute
             */
            $.each(elements, (index, el) => {
                el = $(el);
                const parent = el.parent();

                parent.append(self.getTooltip(el));
            });

            /* Open tooltip on focus if input dont have value */
            elements.on('focus', function () {
                if ($(this).val() == '') {
                    $(this)
                        .parent()
                        .find('.input-tooltip')
                        .addClass('is-active');
                }
            });

            /**
             * Open or hide tooltip based on value of input (empty - show, filled - hide)
             */
            elements.on('keyup', function () {
                if ($(this).val() == '') {
                    $(this)
                        .parent()
                        .find('.input-tooltip')
                        .addClass('is-active');
                } else {
                    $(this)
                        .parent()
                        .find('.input-tooltip')
                        .removeClass('is-active');
                }
            });

            /**
             * Hide tooltip when leaving from input
             */
            elements.on('blur', function () {
                $(this)
                    .parent()
                    .find('.input-tooltip')
                    .removeClass('is-active');
            });
        });
    }

    /**
     * Creates tooltip element
     * @param jQuery el
     */
    getTooltip(el) {
        let tooltip = document.createElement('span');
        tooltip.classList.add('input-tooltip');
        tooltip.innerHTML = el.data('tooltip');

        return tooltip;
    }
}
