export default class CustomSelect {
    constructor(selector) {
        this.selector = selector;

        prestashop.on('updatedProduct', () => {
            this.init();
        });
    }

    init() {
        const customSelects = document.querySelectorAll(this.selector);
        customSelects.forEach((select) => {
            jQuery(select).select2({
                minimumResultsForSearch: -1
            });
        });
    }
}