export default class FiltersModal {
    constructor() {
        this.OVERLAY_FADE = 400;

        this.filtersModal = null;
        this.activeGroup = null;

        $(document).on('as4-After-Set-Results-Contents', () => {
            this.initMobile();
            this.initDynamicCallbacks();

            this.initActiveFiltersCount();
        });

        prestashop.on('updateProductList', () => {
            this.initMobile();
            this.initDynamicCallbacks();

            this.initActiveFiltersCount();
        });
    }

    init() {
        this.filtersModal = $('.filters-modal');
        this.filtersShowResultsMobile = $('.filters-show-results--mobile');

        this.createOverlay();

        this.initCallbacks();
        this.initMobile();

        this.initActiveFiltersCount();
    }

    createOverlay() {
        let overlay = $('.filters-overlay');

        if (!overlay.length) {
            overlay = $("<div class='filters-overlay'> </div>");
            overlay.appendTo(document.body);
        }

        this.overlay = overlay;
    }

    toggleOverlay() {
        this.overlay.css('transform', 'translateY(' + window.scrollY + 'px)');

        if (this.filtersModal) {
            if (this.filtersModal.hasClass('is-active')) {
                this.overlay.fadeIn(this.OVERLAY_FADE);
            } else {
                this.overlay.fadeOut(this.OVERLAY_FADE);
            }
        }
    }

    initMobile() {
        // need to disable check-all when group is not a checkbox group

        let controller = this;

        $('.PM_ASCriterionsGroup').each(function () {
            $(this)
                .find('.PM_ASCriterionsGroupName')
                .on('click', () => {
                    let idGroup = $(this).attr('id');
                    controller.onMobileGroupClick(idGroup);
                });

            $(this)
                .find('.PM_ASCriterionsCheckAll')
                .on('click', (e) => {
                    let checked = $(this).find('input[type=checkbox]:checked');
                    let total = $(this).find('input[type=checkbox]');

                    if (checked.length < total.length) {
                        total.attr('checked', 'checked');
                        $(total[0]).attr('checked', null);
                    } else {
                        total.attr('checked', null);
                        $(total[0]).attr('checked', 'checked');
                    }

                    $(total[0]).trigger('click');
                });
        });

        this.refreshActiveGroup();
    }

    initCallbacks() {

        $(window).on('resize', () => {
            this.onWindowResize();
        });

        $(document).on('click', '.filters-close', (event) => {
            event.stopPropagation();
            this.onClose();
        });

        $(document).on('click', '.filters-show-results', (event) => {
            event.stopPropagation();
            this.onShowResults();
        });

        $(document).on('click', '.filters-clear', (event) => {
            event.stopPropagation();
            this.onClear();
        });

        $(document).on('click', '.filters-toggle', (event) => {
            event.stopPropagation();
            this.onTrigger();
        });

        $(document).on('as4-Criterion-Change', (event) => {
            $('.filters-modal').css('pointer-events', 'none');
        });

        $(document).on('as4-After-Response-Callback', (event) => {
            $('.filters-modal').css('pointer-events', 'all');
            this.refreshActiveGroup();
        });

        this.initDynamicCallbacks();
    }

    initDynamicCallbacks() {
        this.overlay.on('click', () => {
            this.onClose();
        });

        this.filtersModal.find('.PM_ASCriterionsGroup input').on('input', () => {
            this.updateActiveFiltersCount();
        });

        const criterionLists = $('.PM_ASCriterionStepEnable ul');
        const controller = this;

        criterionLists.each(function () {
            const criterionList = $(this);

            $(criterionList).on('wheel', (event) => {
                if ($(window).width() < 992) {
                    return;
                }

                event.stopPropagation();
                event.preventDefault();

                if (controller.isCriterionListOverflow(criterionList)) {
                    controller.onCriterionListWheel(criterionList, event);
                }
            });
        });
    }

    onTrigger() {
        this.filtersModal.toggleClass('is-active');
        this.filtersShowResultsMobile.toggleClass('is-active');

        this.toggleOverlay();

        $('body').toggleClass('no-scroll');
    }

    onClose() {
        this.filtersModal.removeClass('is-active');
        this.filtersShowResultsMobile.removeClass('is-active');

        this.overlay.fadeOut(this.OVERLAY_FADE);

        $('body').removeClass('no-scroll');
    }

    onClear() {
        this.filtersModal.find('.PM_ASResetSearch').trigger('click');
    }

    onShowResults() {
        this.onClose();
    }

    onCriterionListWheel(criterionList, event) {
        const deltaY = -event.originalEvent.deltaY;

        const marginCurrent = parseInt(criterionList.css('margin-top'), 10);

        const marginDelta = 50;
        const marginMax = 0;

        const filtersBodyHeight = $('.filters-body').outerHeight();
        const marginMin = filtersBodyHeight - (criterionList.outerHeight() + 100);

        let marginValue = marginCurrent;

        if (deltaY < 0) {
            marginValue = (marginValue - marginDelta) > marginMin ? (marginValue - marginDelta) : marginMin;
        } else {
            marginValue = (marginValue + marginDelta) < marginMax ? (marginValue + marginDelta) : marginMax;
        }

        criterionList.css('margin-top', marginValue + 'px');
    }

    isCriterionListOverflow(criterionList) {
        const filtersBodyHeight = $('.filters-body').outerHeight();
        return criterionList.outerHeight() > (filtersBodyHeight - 50);
    }

    onMobileGroupClick(idGroup) {
        let group = this.getGroup(idGroup);

        if (group.length) {
            if (this.isGroupActive(group)) {
                this.deactivateGroup(group);
            } else {
                this.activateGroup(group);
            }
        }
    }

    getGroup(idGroup) {
        return $('#' + idGroup);
    }

    isGroupActive(group) {
        return $(group).hasClass('is-active');
    }

    deactivateGroup(group) {
        $('.PM_ASCriterionsGroupList').removeClass('is-active');
        $(group).removeClass('is-active');

        this.activeGroup = null;
    }

    activateGroup(group) {
        $('.PM_ASCriterionsGroupList').addClass('is-active');
        $(group).addClass('is-active');

        this.activeGroup = group.attr('id');
    }

    refreshActiveGroup() {
        if (this.activeGroup) {
            let group = this.getGroup(this.activeGroup);
            this.activateGroup(group);
        }
    }

    initActiveFiltersCount() {
        $('.PM_ASCriterionsGroup').each(function () {
            let activeFiltersCount = $('<span>', {
                class: 'PM_ASCriterionsActiveFiltersCount',
            });

            $(activeFiltersCount).insertAfter(
                $(this).find('.PM_ASCriterionsGroupName')
            );
        });

        this.updateActiveFiltersCount();
    }

    updateActiveFiltersCount() {
        let activeFiltersCountGlobal = 0;

        $('.PM_ASCriterionsGroup').each(function () {
            let activeFilters = $(this).find('input[type=checkbox]:checked');
            let activeFiltersCount = $(this).find(
                '.PM_ASCriterionsActiveFiltersCount'
            );

            activeFiltersCountGlobal += activeFilters.length;
            $(activeFiltersCount).text(activeFilters.length);

            if (activeFilters.length) {
                $(activeFiltersCount).removeClass('is-empty');
            } else {
                $(activeFiltersCount).addClass('is-empty');
            }
        });

        $('.filters-count').text(activeFiltersCountGlobal);

        if (activeFiltersCountGlobal) {
            $('.filters-toggle').addClass('is-active');
        } else {
            $('.filters-toggle').removeClass('is-active');
        }
    }

    onWindowResize() {
        const $scrollIndicator = $('.js-filters-scroll-indicator');

        if (!$scrollIndicator.length) {
            return;
        }

        if ($(window).width() >= 992 && this.isFiltersContentOverflow()) {
            $scrollIndicator.show();
        } else {
            $scrollIndicator.hide();
        }
    }

    isFiltersContentOverflow() {
        const $filters = $('.filters-body');

        if ($filters.length) {
            const $filtersElement = $filters[0];
            return $filtersElement.scrollWidth > $filtersElement.clientWidth;
        }

        return false;
    }
}
