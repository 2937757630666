export default class TouchspinQty {
    init() {
        var qtySelector = $('.qty .bootstrap-touchspin input[type="number"]');

        // PRODUCT PAGE
        $('body').on('change keyup', qtySelector, function (e) {
            if ($(e.target).val() > 1) {
                $(e.target).parent().find('.bootstrap-touchspin-down').fadeIn();
            } else {
                $(e.target)
                    .parent()
                    .find('.bootstrap-touchspin-down')
                    .fadeOut();
            }
        });
    }
}
