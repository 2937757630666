export default class SmoothScroll {
    init() {
        // Select all links with hashes
        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .not('[href="#popupForm"]')
            .not('[href="#promo-code"]')
            .not('.no-scroll')
            .click(function (event) {
                // On-page links
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate(
                            {
                                scrollTop: target.offset().top - 100,
                            },
                            1000,
                            function () {}
                        );
                    }
                }
            });
    }
}
