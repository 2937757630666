import ShowAllLink from './showAllLink';
import BackLink from './backLink';

export default function Nav(backItem, showAllItem) {
    if (!backItem || !showAllItem) {
        return;
    }
    let result = `<ul class="shiftnav__nav">`;

    result += BackLink(backItem);

    result += ShowAllLink(showAllItem);

    result += `</ul>`;

    return result;
}
