import debounce from 'lodash.debounce';
import StickyNav from './sticky-nav';

export default class Header {
    constructor() {
        this.onReady = this.onReady.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.headerTop = null;

        new StickyNav({
            selector: '.header-top',
            maxWidth: 991,
            fixedDependencies: ['#topHighlights'],
        }).init();

        new StickyNav({
            selector: '.header-bottom',
            minWidth: 992,
            staticDependencies: ['.header-top'],
            fixedDependencies: ['#topHighlights'],
        }).init();
    }

    init() {
        $(this.onReady);
    }

    onReady() {
        this.headerTop = $('.header-top');
        $(window).on('scroll', debounce(this.onScroll, 100));
        $(window).on('resize', debounce(this.onScroll, 100));
        this.makeAltLogo();
        this.toggle();
    }

    onScroll() {
        this.toggle();
    }

    onResize() {
        this.toggle();
    }

    toggle() {
        if (window.innerWidth < 992) {
            if (window.pageYOffset > 50) {
                this.headerTop.addClass('scrolled');
            } else {
                this.headerTop.removeClass('scrolled');
            }
        }
    }

    makeAltLogo() {
        const altLogo = this.headerTop.find('.logo-alt');
        const userInfo = this.headerTop.find('#_desktop_user_info');

        altLogo.appendTo(userInfo);
        // console.log(altLogo);
    }
}
