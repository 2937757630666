export default class CustomSpinner {

    addSpinner(el) {

        if(!this.isSpinner(el)) {
            $(el).prepend('<div class="loading-overlay"><div class="spinner"></div></div>');
        }
    }

    removeSpinner(el) {

        if (this.isSpinner(el)) {
            $(el).find('.loading-overlay').remove();
        }
    }  

    isSpinner(el) {
        return $(el + ' .loading-overlay').length > 0;
    }
    
}