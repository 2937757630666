import LinkList from './linkList';
import Nav from './nav';
import MenuBefore from './menuBefore';
import MenuAfter from './menuAfter';

export default function View(
    items,
    backItem = null,
    showAllItem = null,
    beforeContent = null,
    afterContent = null
) {
    let result = '';

    if (backItem && showAllItem) {
        result += Nav(backItem, showAllItem);
    }

    result += MenuBefore(beforeContent);

    result += LinkList(items);

    result += MenuAfter(afterContent);

    return result;
}
