import OutsideJQueryPlugin from './helpers/outside';


import 'slick-carousel'; // dodane zamiast elementorowego slidera (nowsza wersja)
import InputTooltip from './components/global/input-tooltip';
import MenuToggle from './components/global/menu-toggle';
import Header from './components/global/header';
import FeaturedProducts from './components/global/featured-products';
import ShiftNav from './components/global/shiftnav/shiftnav';
import TouchspinQty from './components/global/touchspin';
import FiltersModal from './components/global/filters-modal';
import SmoothScroll from './components/global/smooth-scroll';
import SlickSlider from './components/global/slick-slider';
import ElementorInstagramSlider from './components/global/elementor-instagram-slider';
import SetTitleHeight from './components/elementor/content-blocks';
import sectionWidth from './components/elementor/main';
import CategorySpinner from './components/category/spinner';
import CustomSelect from './components/global/custom-select';

new InputTooltip().init();
new MenuToggle().init();

new Header().init();

new FeaturedProducts().init();

new ShiftNav({
    menuWrapperSelector: '.mm_menus_ul_wrapper',
    maxWidth: 992,
}).init();

new TouchspinQty().init();
new SmoothScroll().init();

$(() => {
    new OutsideJQueryPlugin().init();
    
    new SlickSlider().init();
    new FiltersModal().init();
    new SetTitleHeight().init();
    new sectionWidth().init();
    new CategorySpinner().init();
    new ElementorInstagramSlider().init();
    
    const customSelect = new CustomSelect('.js-custom-select');
    customSelect.init();
});


