export default class StickyNav {
    constructor({
        selector,
        minWidth,
        maxWidth,
        staticDependencies,
        fixedDependencies,
    }) {
        /* Set options */
        this.options = {
            selector: selector,
            minWidth: minWidth ? minWidth : 0,
            maxWidth: maxWidth ? maxWidth : 10000,
            staticDependencies: staticDependencies ? staticDependencies : [],
            fixedDependencies: fixedDependencies ? fixedDependencies : [],
        };

        /* Set default fields */
        this.sticky = null;
        this.body = null;
        this.scrollBreakpoint = 0;
        this.dependendElements = {
            static: [],
            fixed: [],
        };

        /* Set default body margin value */
        this.lastWidth = window.innerWidth;

        /* Bind 'this' variable to proper context */
        this.onReady = this.onReady.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onResize = this.onScroll.bind(this);
        this.update = this.update.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    init() {
        $(this.onReady);
    }

    /**
     * Initalizes elements and listeners
     */
    onReady() {
        this.sticky = $(this.options.selector);
        this.body = $('body');

        this.setDependencies();

        $(window).on('scroll', this.onScroll);
        $(window).on('resize', this.onResize);

        this.toggle();
    }

    /**
     * Update sticky on scroll
     */
    onScroll() {
        this.toggle();
    }

    /**
     * Update sticky on resize
     */
    onResize() {
        this.toggle();
    }

    /**
     * Update sticky based on min and max width
     */
    toggle() {
        if (this.shouldBeActive()) {
            this.updateMargins();
        }

        if (this.shouldBeActive()) {
            this.update();
        } else if (this.isSticky()) {
            this.disable();
        }

        this.updateGlobalVariables();
    }

    /**
     * Updates nav height variable set in global scope
     */
    updateGlobalVariables() {

        if(this.isSticky()) {
            window.navHeight = this.sticky.outerHeight() + this.getFixedDependenciesHeight();
        } else {
            window.navHeight = this.sticky.outerHeight() + this.getFixedDependenciesHeight() + this.getStaticDependenciesHeight();
            
            if(this.shouldBeActive()) {
                 window.navHeight -= window.pageYOffset;
            }
        }

        window.dispatchEvent(new CustomEvent('sticky_nav_update'));
    }

    /**
     * Returns true if sticky is in min max width range and should be updating
     * @returns boolean
     */
    shouldBeActive() {
        return (
            window.innerWidth >= this.options.minWidth &&
            window.innerWidth <= this.options.maxWidth
        );
    }

    /**
     * Returns true if element has class "sticky"
     * @returns boolean
     */
    isSticky() {
        return this.sticky.hasClass('sticky');
    }

    /**
     * Creates jQuery element objects from passed selectors (needed for calculations)
     */
    setDependencies() {
        for (const selector of this.options.staticDependencies) {
            this.dependendElements.static.push($(selector));
        }
        for (const selector of this.options.fixedDependencies) {
            this.dependendElements.fixed.push($(selector));
        }
    }

    /**
     * Returns sum of static elements heights
     * @returns number
     */
    getStaticDependenciesHeight() {
        let height = 0;
        for (const el of this.dependendElements.static) {
            height += Number.parseInt(el.outerHeight());
        }

        return height;
    }

    /**
     * Returns sum of fixed elements heights
     * @returns number
     */
    getFixedDependenciesHeight() {
        let height = 0;
        for (const el of this.dependendElements.fixed) {
            height += Number.parseInt(el.outerHeight());
        }

        return height;
    }

    /**
     * Sets margins on body and sticky element to handle change from static to fixed state
     * @returns void
     */
    updateMargins() {
        if (!this.shouldBeActive()) {
            return;
        }

        if (this.isSticky()) {
            this.sticky.css(
                'margin-top',
                this.getFixedDependenciesHeight() + 'px'
            );
            this.body.css(
                'margin-top',
                this.sticky.outerHeight() +
                    this.getFixedDependenciesHeight() +
                    'px'
            );
        } else {
            this.sticky.css('margin-top', 0);
            this.resetBodyMargin();
        }
    }

    /**
     * Toggle element based on window size and dependencies height
     */
    update() {
        if (window.pageYOffset >= this.getStaticDependenciesHeight()) {
            this.enable();
        } else {
            this.disable();
        }
    }

    /**
     * Adds sticky behavior to element
     * @returns void
     */
    enable() {
        if (this.isSticky()) {
            return;
        }
        this.sticky.addClass('sticky');
        this.updateMargins();
    }

    /**
     * Removes sticky behavior from element
     * @returns void
     */
    disable() {
        if (!this.isSticky()) {
            return;
        }
        this.sticky.removeClass('sticky');
        this.updateMargins();
    }

    /**
     * Removes margin style added when making sticky behavior
     */
    resetBodyMargin() {
        this.body.removeAttr('style');
    }
}
