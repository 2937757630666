export default function ShowAllLink(item) {
    let el = item.el.cloneNode(Infinity);

    el.innerHTML =
        prestashop.modules.arkon_themeconfig.translations.show_all ||
        'Zobacz wszystkie';

    let result = `
    <li class="shiftnav__navlink shiftnav__navlink--show-all" title="${el.innerHTML}"> 
        ${el.outerHTML}
    </li>
    `;

    return result;
}
