export default class MenuToggle {
    constructor() {
        this.fakeBtnSelector = '.ybc-menu-button > .hamburger';
        this.btnSelector = '.ybc-menu-toggle';
        this.menuWrapperSelector = '.mm_menus_ul';

        this.fakeBtn = null;
        this.btn = null;
        this.menuWrapper = null;

        this.menuToggleEvent = new Event('menuToggled');

        this.onReady = this.onReady.bind(this);
        this.onClickFakeBtn = this.onClickFakeBtn.bind(this);
        this.setMenuPositon = this.setMenuPositon.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    init() {
        $(this.onReady);
    }

    onReady() {
        this.fakeBtn = $(this.fakeBtnSelector);
        this.btn = $(this.btnSelector);
        this.menuWrapper = $(this.menuWrapperSelector);

        this.fakeBtn.on('click', this.onClickFakeBtn);

        $(document).on('menuToggled', this.setMenuPositon);

        $(window).on('resize', this.onResize);

        this.onResize();
    }

    onClickFakeBtn() {
        if (!this.fakeBtn.hasClass('is-active')) {
            this.fakeBtn.addClass('is-active');
            $('body').addClass('no-scroll');
        } else {
            this.fakeBtn.removeClass('is-active');
            $('body').removeClass('no-scroll');
        }

        this.btn.click();

        document.dispatchEvent(this.menuToggleEvent);
    }

    onResize() {
        if (window.innerWidth > 991) {
            if (this.fakeBtn.hasClass('is-active')) {
                this.fakeBtn.click();
            }

            if (this.menuWrapper.attr('style')) {
                this.menuWrapper.removeAttr('style');
            }
        } else {
            this.setMenuPositon();
        }
    }

    setMenuPositon() {
        const headerTop = $('.header-top');

        let top = null;
        let margin = null;

        if (window.innerWidth < 568) {
            const headerLogo = $('.header-logo');
            if (headerLogo.length) {
                margin = window.scrollY ? 0 : headerLogo.outerHeight();
            }
        } else {
            margin = 0;
        }

        if (headerTop.length) {
            top = window.navHeight;
            if (!headerTop.hasClass('scrolled')) {
                if (margin == 0 || window.innerWidth > 567) {
                    top += 20;
                }
            }
        }

        if (top) {
            this.menuWrapper.css('top', top - 2 + 'px');
        }

        if (margin !== null) {
            this.menuWrapper.css('margin-top', margin + 'px');
            if(this.menuWrapper.length) {
                $('.mm_menus_ul').css(
                    'height',
                    window.innerHeight - this.menuWrapper.position().top - margin
                );
            }
        }
    }
}
