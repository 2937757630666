export default function BackLink(item) {
    const el = $(item.el);

    let result = `<li class="shiftnav__navlink shiftnav__navlink--back">`;

    result += `
        <button 
            title="${el.text()}" 
            class="shiftnav__toggle" 
            data-menu="${item.menu}"
            data-column="${item.column}"
            data-block="${item.block}"
            >${el.text()}
        </button>
    `;
    result += '</li>';
    return result;
}
