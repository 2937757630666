export default class SetTitleHeight {
    init() {
        this.run();
    }
    run() {

        $(window).on('load resize', function () {
            var max_height = 0;
            var height = 0;
            var block = '.content-block-title';

            $(block).css({
                'min-height': max_height,
            });

            $(block).each(function(){
                height = $(this).height();
                if(height > max_height) max_height = height;
            });
            
            $(block).css({
                'min-height': max_height,
            });
        });
    }
}