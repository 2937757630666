export default class ElementorInstagramSlider {
    init() {
        $('.elementor-custom-instagram-slider').removeData('slider_options');
        $('.elementor-custom-instagram-slider .elementor-image-carousel.slick-initialized').slick('unslick');
        $('.elementor-custom-instagram-slider .elementor-image-carousel:not(.slick-initialized)').slick({
            dots: true,
            arrows: false,

            autoplay: false,

            slidesToShow: 4,
            slidesToScroll: 1,

            waitForAnimate: false,
            swipeToSlide: true,

            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
            ],
        });
    }
}