import Link from './link';

export default function LinkList(items) {
    let html = '<ul class="shiftnav__body">';

    for (const item of items) {
        html += Link(item);
    }

    html += '</ul>';

    return html;
}
