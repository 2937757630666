import CustomSpinner from './../../components/global/custom-spinner';
export default class CategorySpinner {
    constructor() {}

    init() {
        this.products = '#js-product-list .products';
        this.spinner = new CustomSpinner();
        this.run();
    }

    run() {
        if ($(this.products).length < 1) {
            return;
        }

        const self = this;

        prestashop.on('updateFacets', () => {
            self.spinner.addSpinner(self.products);
        });

        prestashop.on('updateProductList', () => {
            self.spinner.removeSpinner(self.products);
        });
    }
}
