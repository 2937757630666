export default class SlickSlider {
    init() {
        $(function () {
            $(window).resize(function(){
                if($(this).width() <= 768){
                    var $status = $('.slick-dots');
                    var $slickElement = $('.slider-toplist');
                    let outOfString = ' ' + prestashop.modules.arkon_themeconfig.translations['out_of'] + ' ';

                    $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                        var i = (currentSlide ? currentSlide : 0) + 1;
                        if (slick.slideCount != null) {
                            $status.text(i + outOfString + slick.slideCount);
                        } else {
                            $status.text(' ');
                        }
                    });

                    $slickElement.slick({
                        slide: 'img',
                        autoplay: true,
                        dots: true
                    });
                }
            }).resize();
        });
    }
}